<template>
    <div class="r_content">
        <div id="demo" class="block">
            <vue-seamless-scroll :data="rbookInfos" :class-option="classOption" @click="handleClick($event)">
                <div :style="{ width: '100%', height: '360px' }">
                    <div class="box" v-for="(m, idx) in rbookInfos" :key="idx"
                        :style="{ width: boxWidth + 'px', height: boxWidth + (boxWidth / 3) + 'px' }">
                        <img :src="m.pic" alt="" style="width:100%" :data-id="m.id">
                    </div>
                </div>
            </vue-seamless-scroll>

        </div>
        <div class="top"></div>
        <div class="bottom"></div>
        <div class="layer" style="display: none;">
            <a href="javascript:void(0)"><img src="@/assets/images/icon_close.svg" /></a>
        </div>
        <van-popup v-model:show="showDetail" :round="true" @closed="handleClose"
            :style="{ width: '720px', height: modalHeight, top: popupTop }">
            <div ref="bookpopup" style="width:100%;height:100%;background:white">
                <van-loading v-if="bookUrlLoading" text-color="#0094ff" color="#0094ff" size="120" type="spinner" vertical
                    style="top:40%;left:0;right:0;bottom:0;margin:auto;z-index:999;position:absolute">加载中</van-loading>
                <iframe id="bookIframe" :style="{ width: '100%', height: '100%' }" frameborder="0">
                </iframe>
                <div
                    style="width: 200x;height: 200px;position: fixed;margin: auto;top: 5px;right: 5px;background-color: #fff;padding: 5px;border-radius: 8px;opacity: 0.9;">
                    <div id="qrcode" style=""></div>
                    <div
                        style="background: rgba(255,255,255,0.5);margin: 20px 0 0;color: #000;border-radius: 10px;font-size: 14px;padding: 5px 0;">
                        扫码阅读</div>
                </div>
                <van-button color="gray" style="position: fixed;margin: auto;top: 5px;left: 5px;width:50px;height:50px;"
                    type="default" icon="close" size="large" round @click="() => showDetail = false"></van-button>
            </div>
        </van-popup>

        <van-popup v-model:show="showLoading" :round="true" style="height:300px;width:300px;">
            <van-loading text-color="#0094ff" color="#0094ff" size="120" type="spinner" vertical
                style="margin-top:30%">加载中</van-loading>
        </van-popup>

        <!-- <van-button color="#33da63" style="background:rgba(51,218,99,0.85);" type="success"
                        icon="live" size="large" @click="getBookInfos(1)">读书</van-button> -->
        <van-floating-bubble icon="search" axis="y" @offset-change="handleChange"
            style="width: 240px;height: 220px;font-size: 24px;background-color: transparent;border-radius: 0px;"
            v-model:offset="offset">
            <div class="selector">
                <ul>
                    <li style="display: none">
                        <input id="c1" type="checkbox">
                        <label for="c1"><a href="###"></a></label>
                    </li>
                    <li style="display: none">
                        <input id="c2" type="checkbox">
                        <label for="c2"><a href="###"></a></label>
                    </li>
                    <li style="display: none">
                        <input id="c3" type="checkbox">
                        <label for="c3"><a href="###"></a></label>
                    </li>
                    <li style="display: none">
                        <input id="c4" type="checkbox">
                        <label for="c4"><a href="###"></a></label>
                    </li>
                    <li style="display: none">
                        <input id="c5" type="checkbox">
                        <label for="c5"><a href="###"></a></label>
                    </li>
                    <li>
                        <input id="c8" type="checkbox">
                        <label for="c8">
                            <a href="###" @click="showSearch">
                                <img src="../assets/images/nav/icon_search.svg" />
                                <div>搜索</div>
                            </a>
                        </label>
                    </li>
                    <li>
                        <input id="c7" type="checkbox">
                        <label for="c7">
                            <a href="###" @click="getBookInfos(2)">
                                <img src="../assets/images/nav/icon_listen.svg" />
                                <div>听书</div>
                            </a>
                        </label>
                    </li>
                    <li>
                        <input id="c6" type="checkbox">
                        <label for="c6">
                            <a href="###" @click="getBookInfos(1)">
                                <img src="../assets/images/nav/icon_read.svg" />
                                <div>读书</div>
                            </a>
                        </label>
                    </li>
                </ul>
                <button>
                    <div class="container">
                        <div class="dot"></div>
                    </div>
                </button>
            </div>
        </van-floating-bubble>

        <!-- <van-floating-bubble icon="search" @click="getBookInfos(2)" axis="xy" @offset-change="handleChange(2, $event)"
            style="width: 100px;height: 50px;font-size: 24px;background-color: rgba(51, 218, 99, 0.85);border-radius: 0px;"
            v-model:offset="offset2"><van-icon name="audio" size="25" />听书</van-floating-bubble>

        <van-floating-bubble icon="search" @click="showSearch" axis="xy" @offset-change="handleChange(3, $event)"
            style="width: 100px;height: 50px;font-size: 24px;background-color: rgba(51, 218, 99, 0.85);border-radius: 0px;"
            v-model:offset="offset3"><van-icon name="search" size="25" />搜索</van-floating-bubble> -->

        <van-popup v-model:show="searchVisiable" :round="true" @closed="() => searchVisiable = false" :style="modalStyle">
            <van-search ref="inputFile" v-model="bookName" @focus="onFocus" placeholder="关键字"></van-search>
            <SimpleKeyboard ref="SimpleKeyboard" @onChange="onChangeKeyboard" @search="handelSearch"
                v-if="!searchBookVisiable" @clear="handleClear" @bksp="handleBksp" />
            <div :style="{ width: '100%', height: '360px' }" v-else-if="bookList.length > 0">
                <div class="box" v-for="(m, idx) in bookList" :key="idx"
                    :style="{ width: boxWidth + 'px', height: boxWidth + (boxWidth / 3) + 'px' }">
                    <img :src="m.picUrl" alt="" style="width:100%" :data-id="m.id" @click="handleShowBook(m)">
                </div>
            </div>
            <van-empty description="暂无数据" v-else />
        </van-popup>
    </div>
</template>
  
<script>
import '@/assets/style.css'
import jsrsasign from 'jsrsasign'
import QRCode from 'qrcodejs2'
import axios from 'axios'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
import { sign, ksort } from '@/utils/wsign'
import { showFailToast, showToast } from 'vant'
import SimpleKeyboard from './SimpleKeyboard.vue'
import $ from 'jquery'
const appId = process.env.VUE_APP_APPID
const rentId = process.env.VUE_APP_RENTID
const userId = process.env.VUE_APP_USERID
const publicKeyStr = process.env.VUE_APP_PUBLIC_KEY
const companyId = process.env.VUE_APP_COMPANYID
var angleStart = -360;
export default {
    name: 'WaterFall',
    components: {
        vueSeamlessScroll,
        SimpleKeyboard
    },
    data() {
        return {
            modalStyle: { width: '720px', height: '900px', top: '70%', background: 'white' },
            bookName: '',
            searchVisiable: false,
            searchBookVisiable: false,
            host: 'https://s.zhangyue.com',
            bookType: 1,//1-电子书，2-音频书
            showDetail: false,
            boxWidth: 200,
            boxHeight: 350,
            classOption: {
                step: 3, // 数值越大速度滚动越快
                limitMoveNum: 40, // 开始无缝滚动的数据量 this.cityData.length
                hoverStop: false, // 是否开启鼠标悬停stop
                direction: 0, // 0向下 1向上 2向左 3向右
                openWatch: true // 开启数据实时监控刷新dom
                // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            },
            // companyId: '105178',
            bookFileurl: '',
            rbookIds: [],
            lbookIds: [],
            rbookInfos: [],
            lbookInfos: [],
            popupTop: 0,
            modalHeight: '1140px',
            showLoading: false,
            bookUrlLoading: false,
            page: 0,
            lbpage: 0,//听书页数
            searchPage: 1,
            searchPageSize: 10,
            bookList: [],
            offset: { x: 856, y: 1600 },
        }
    },
    created() {
        var h1 = window.localStorage.getItem('van_btn')
        var newH1
        var windowHeight = window.innerHeight
        if (windowHeight <= 1920) {
            newH1 = windowHeight / 2
        } else if (windowHeight > 1920 && windowHeight < 3840) {
            newH1 = windowHeight / 2
        } else {
            newH1 = windowHeight / 2
        }
        if (h1 && h1 !== 'undefined') {
            this.offset = JSON.parse(h1)
        } else {
            this.offset.y = newH1
        }
        this.init()
    },
    mounted() {
        const clientHeight = document.documentElement.clientHeight
        if (clientHeight <= 1920) {
            this.modalHeight = document.documentElement.clientHeight / 2 + 'px'

        }
        $('.selector button').click(function () {
            toggleOptions($(this).parent());
        });
        $('.container').click('on', function () {
            $(this).toggleClass('hover');
        });
        // else if (clientHeight > 1920) {
        //     this.modalHeight = document.documentElement.clientHeight / 3 + 'px'
        // }
    },
    methods: {
        handleChange(e) {
            window.localStorage.setItem("van_btn", JSON.stringify(e))
        },
        showSearch() {
            this.modalStyle.top = this.offset.y + 'px'
            this.searchVisiable = true
        },
        onFocus() {
            this.searchBookVisiable = false
            document.activeElement.blur()
        },
        onChangeKeyboard(v) {
            this.bookName = v

        },
        handelSearch() {
            const that = this
            if (that.bookName === '') {
                showFailToast('请输入书名')
                return
            }
            var url = '/zysearch/search/index'
            var ts = Math.round(new Date().getTime() / 1000).toString()
            const obj = {
                keyword: that.bookName,
                page: that.searchPage,
                pageSize: that.searchPageSize,
                type: that.bookType,
                rentId: rentId,
                appId: appId,
                timestamp: ts,
            }
            const signstr = sign(obj)
            const parmas = ksort(obj)
            url += '?' + parmas + '&sign=' + signstr
            axios.get(url).then(res => {
                that.bookList = []
                if (res.status === 200) {
                    if (res.data.code === 0) {
                        if (res.data.body.bookList) {
                            that.bookList = res.data.body.bookList
                        }
                    }
                } else {
                    showToast('查询失败')
                }
                that.searchBookVisiable = true
            })
        },
        init() {
            this.getComBookFileUrl()
        },
        handleClear() {
            this.bookName = ''
        },
        handleBksp() {
            if (this.bookName.length > 0) {
                if (this.bookName.length === 1) {
                    this.$refs.SimpleKeyboard.backClear()
                    this.bookName = ''
                } else {
                    this.bookName = this.bookName.substring(0, this.bookName.length - 1)
                }
            }
        },
        handleShowBook(bookObj) {
            const that = this
            that.searchVisiable = false
            that.bookUrlLoading = true
            that.popupTop = '60%'
            if (bookObj.bookId) {
                var ts = Math.round(new Date().getTime() / 1000).toString()
                const cid = '1'
                const obj = {
                    appId: appId,
                    rentId: rentId,
                    timestamp: ts,
                    userId: userId
                }
                const signstr = sign(obj)
                var bookDetailUrl = ''
                if (bookObj.bookType === 1) {
                    bookDetailUrl = that.host + '/read' + "?" + 'bid=' + bookObj.bookId + '&cid=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else if (bookObj.bookType === 2) {
                    bookDetailUrl = that.host + '/audio/ting' + "?" + 'bookId=' + obj.bookId + '&chapterId=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else {
                    showFailToast('数据异常')
                    return
                }
                that.showDetail = true
                that.$nextTick(() => {
                    let iframe = document.getElementById('bookIframe')
                    iframe.src = bookDetailUrl
                    if (iframe.attachEvent) {// IE下
                        iframe.attachEvent("onload", function () {
                            //    后续操作
                            that.bookUrlLoading = false
                        });
                    } else {
                        iframe.onload = function () {
                            //    后续操作
                            that.bookUrlLoading = false
                            that.qrcodeScan(bookDetailUrl)

                        };
                    }
                })
            }
        },
        handleClick(e) {
            const that = this
            that.bookUrlLoading = true
            const bid = e.target.dataset.id
            if (e.y > 5000) {
                that.popupTop = (e.y - 500) + 'px'
            } else if (e.y < 1000) {
                that.popupTop = (e.y + 500) + 'px'
            } else {
                that.popupTop = e.y + 'px'
            }
            if (bid) {
                var ts = Math.round(new Date().getTime() / 1000).toString()
                const cid = '1'
                const obj = {
                    appId: appId,
                    rentId: rentId,
                    timestamp: ts,
                    userId: userId
                }
                const signstr = sign(obj)
                var bookDetailUrl = ''
                if (that.bookType === 1) {
                    bookDetailUrl = that.host + '/read' + "?" + 'bid=' + bid + '&cid=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else if (that.bookType === 2) {
                    bookDetailUrl = that.host + '/audio/ting' + "?" + 'bookId=' + bid + '&chapterId=' + cid + '&' + ksort(obj) + "&sign=" + signstr
                } else {
                    showFailToast('数据异常')
                    return
                }
                that.showDetail = true
                that.$nextTick(() => {
                    let iframe = document.getElementById('bookIframe')
                    iframe.src = bookDetailUrl
                    if (iframe.attachEvent) {// IE下
                        iframe.attachEvent("onload", function () {
                            //    后续操作
                            that.bookUrlLoading = false
                        });
                    } else {
                        iframe.onload = function () {
                            //    后续操作
                            that.bookUrlLoading = false
                            that.qrcodeScan(bookDetailUrl)

                        };
                    }
                })
            }
        },
        singer(str) {
            let signPrivateKey = '-----BEGIN PRIVATE KEY-----' + publicKeyStr + '-----END PRIVATE KEY-----';
            let sig = new jsrsasign.crypto.Signature({ "alg": "SHA1withRSA", "prov": "cryptojs/jsrsa", "prvkeypem": signPrivateKey });
            var hashAlg = 'sha1'; // 设置sha1
            var signStr = sig.signString(str, hashAlg); // 加签
            return jsrsasign.hex2b64(signStr)
        },
        getComBookFileUrl() {
            const sign = this.singer('companyId=' + companyId)
            const obj = {
                companyId: companyId,
                sign: sign
            }
            axios({
                url: '/api1/nd_activity/out/company/v3/bookIds',
                method: 'post',
                data: obj,
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data.code === 0) {
                        this.bookFileurl = res.data.body.bookFileurl
                        this.getComBookIds()
                    }
                } else {
                    showFailToast('请求失败')
                }
            })
        },
        getComBookIds() {
            if (this.bookFileurl !== '') {
                const url = this.bookFileurl.replaceAll('http://cloud.d.ireader.com', '/api')
                axios.get(url).then(res => {
                    if (res.status === 200) {
                        const data = res.data
                        const rBooks = data.find(item => item.type === 1)
                        const lBooks = data.find(item => item.type === 2)
                        if (rBooks) {
                            this.rbookIds = this.tranBookIds(rBooks.bookIds)
                            this.getBookInfos(this.bookType)
                            setInterval(() => {
                                if (this.bookType === 1) {
                                    this.page = this.page + 1
                                } else {
                                    this.lbpage = this.lbpage + 1
                                }
                                this.getBookInfos(this.bookType, 1)
                            }, 5 * 60 * 1000)
                        } else {
                            showFailToast('图书数据异常')
                        }
                        if (lBooks) {
                            this.lbookIds = this.tranBookIds(lBooks.bookIds)
                        } else {
                            showFailToast('听书数据异常')
                        }
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                showFailToast('图书数据请求失败')
            }
        },
        getBookInfos(type, stateless) {
            if (stateless !== 1) {
                this.showLoading = true
            }
            this.bookType = type
            const sign = this.singer('companyId=' + companyId)
            var showBooks = []
            if (type === 1) {
                var start = this.page * 120
                var end = start + 120
                if (end > this.rbookIds.length) {
                    this.page = 0
                    start = 0
                    end = 120
                }
                if (this.rbookIds.length > 120) {
                    showBooks = this.rbookIds.slice(start, end)
                } else {
                    showBooks = this.rbookIds
                }
            }
            if (type === 2) {
                var lstart = this.lbpage * 120
                var lend = lstart + 120
                if (lend > this.lbookIds.length) {
                    this.lbpage = 0
                    lstart = 0
                    lend = 120
                }
                if (this.lbookIds.length > 120) {
                    showBooks = this.lbookIds.slice(lstart, lend)
                } else {
                    showBooks = this.lbookIds
                }
            }

            const obj = {
                companyId: companyId,
                bookIds: showBooks,
                type: this.bookType,
                sign: sign
            }
            axios({
                url: '/api1/nd_activity/out/company/bookInfos',
                method: 'post',
                data: obj,
                timeout: 60000
            }).then(res => {
                if (res.status === 200) {
                    const array = []
                    for (let key in res.data.body) {
                        array.push({
                            id: key,
                            pic: res.data.body[key].pic
                        })
                    }
                    this.rbookInfos = array

                    this.showLoading = false
                } else {
                    showFailToast('请求失败')
                    this.showLoading = false
                }
            })
        },
        tranBookIds(obj) {
            let arry = []
            for (let key in obj) {
                arry.push(obj[key])
            }
            return this.shuffle(arry)
        },
        shuffle(array) {//对掌阅数据重新洗牌，防止同一类封面的书籍出现在同一个界面
            let res = [], random
            while (array.length > 0) {
                random = Math.floor(Math.random() * array.length)
                res.push(array[random])
                array.splice(random, 1)
            }
            return res
        },
        qrcodeScan(url) {
            const qrcodeDiv = document.getElementById("qrcode")
            qrcodeDiv.innerHTML = ''
            // eslint-disable-next-line no-unused-vars
            const qrcode = new QRCode(qrcodeDiv, {
                width: 200, // 二维码宽度 
                height: 200, // 二维码高度
                text: url, // 浏览器地址url
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            })
        },
        handleClose() {
            const iframe = document.getElementById('bookIframe')
            iframe.src = ''
        },
    }
}
function rotate(li, d) {
    $({
        d: angleStart
    }).animate({
        d: d
    }, {
        step: function (now) {
            $(li)
                .css({
                    transform: 'rotate(' + now + 'deg)'
                })
                .find('label')
                .css({
                    transform: 'rotate(' + (-now) + 'deg)'
                });
        },
        duration: 0
    });
}
function toggleOptions(s) {
    $(s).toggleClass('open');
    var li = $(s).find('li');
    var deg = $(s).hasClass('half') ? 180 / (li.length - 1) : 360 / li.length;
    for (var i = 0; i < li.length; i++) {
        var d = $(s).hasClass('half') ? (i * deg) - 90 : i * deg;
        $(s).hasClass('open') ? rotate(li[i], d) : rotate(li[i], angleStart);
    }
}
</script>
<style lang="less" scoped>
/*盒子样式*/
.selector {
	position: absolute;
	right: 12px;
}
/*按钮*/
    .selector,.selector button {
	font-family:'Oswald',sans-serif;
	font-weight:300;
}
.selector button {
	position:relative;
	width: 72px;
	height: 72px;
	background: url(../assets/images/nav/btn.svg) no-repeat;
	background-size: 100%;
	border-radius:50%;
	border:0;
	color:white;
	font-size:20px;
	cursor:pointer;
	box-shadow:0 3px 3px rgba(0,0,0,0.1);
	transition:all .1s;
}
.selector button:hover {
	background: rgba(138,255,185,0.75);
	box-shadow: 0 0 12px rgba(138,255,185,0.75);
}
.selector button:focus {
	outline:none;
}
.selector ul {
	position:absolute;
	list-style:none;
	padding:0;
	margin:0;
	top:-20px;
	right:-20px;
	bottom:-20px;
	left:-20px;
}
.selector li {
	position:absolute;
	width:0;
	height:100%;
	margin:0 50%;
	-webkit-transform:rotate(-360deg);
	transition:all 0.8s ease-in-out;
}
.selector li input {
	display:none;
}
.selector li input + label {
	position:absolute;
	left:50%;
	bottom:100%;
	width:0;
	height:0;
	line-height:1px;
	margin-left:0;
	background: url(../assets/images/nav/icon_bg.svg) no-repeat;
	background-size: 100%;
	border-radius:50%;
	text-align:center;
	font-size:1px;
	overflow:hidden;
	cursor:pointer;
	box-shadow:none;
	transition:all 0.8s ease-in-out,color 0.1s,background 0.1s;
}
.selector li input + label a  img{
    width: 18px;
    display: block;
    margin: 10px auto 0;
}
.selector li input + label a  img span{
	color: #000;
	position: absolute;
	top: 10px;
	width: 100%;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
}
.selector li input + label:hover {
	background: rgba(62,221,119,0.85);
}
.selector li input:checked + label {
	background:#5cb85c;
	color:white;
}
.selector li input:checked + label:hover {
	background:#449d44;
}
.selector.open li input + label {
	width:60px;
	height:60px;
	line-height:60px;
	margin-left:-28px;
	box-shadow:0 3px 3px rgba(0,0,0,0.1);
	font-size:14px;
}
.selector.open li input + label a {
	text-decoration:none;
	color:#fff;
}
.selector.open li input + label a .tx_t{
	position: absolute;
	top: 10px;
	width: 100%;
	font-weight: 300;
}
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 72px;
    height: 72px;
    cursor: pointer;
}
.dot {
    position: absolute;
    bottom: 33px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 3px;
    height: 3px;
    background: #fff;
    border-radius: 50%;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.container.hover .dot {
    background: transparent;
    bottom: 27px;
}
.dot::before,
.dot::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
.container.hover .dot::before,
.container.hover .dot::after {
    height: 21px;
    border-radius: 0;
}
.dot::before {
    left: -8px;
}
.container.hover .dot::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    transform-origin: right bottom;
    border-radius: 9px;
}
.dot::after {
    right: -8px;
}
.container.hover .dot::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    border-radius: 9px;
}
</style>