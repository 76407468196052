import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)
import { Popup, Loading, Dialog, Toast, Icon, Col, Row, Button, Search, Empty, FloatingBubble } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';
app.use(Popup)
app.use(Loading)
app.use(Dialog)
app.use(Toast)
app.use(Icon)
app.use(Col)
app.use(Row)
app.use(Button)
app.use(Search)
app.use(Empty)
app.use(FloatingBubble)
app.mount('#app')
